<!-- eslint-disable max-len -->
<template>
  <div class="member_show" >
    <div class="show_title">
      <img src="img/account/icon_title_1.svg" alt="">
      <h4 class="txt-bold">會員資料</h4>
    </div>

    <form>
      <div class="show_inputbox">
        <!-- <div class="input_item full">
          <p class="mg7">會員等級</p>
          <div class="fill_in">
            <div class="col100">
              <div class="rank_note">
                <p class="rank_name txt-bold txt-blue">
                  <img src="img/account/level_1.svg" alt="">&nbsp;
                  一般會員
                </p>
                <p>
                  本年度升級
                  <span class="txt-blue">VIP會員</span>
                  條件：消費金額還需&nbsp;
                  <span class="txt-blue txt-bold">$2,100</span>
                </p>
                <router-link
                  :to="{ name: 'MemberLevel' }"
                  class="rank_rule txt-grey txt-underline p">
                    會員分級規章及權益
                </router-link>
              </div>
            </div>
          </div>
        </div> -->

        <div class="input_item">
          <p class="mg14" style="width: auto;">
            <span class="txt-redorange">*</span>
            為必填欄位，請確實填寫以確保您的會員權益。
          </p>
        </div>

        <div class="input_item">
          <p class="mg14">姓名<span class="txt-redorange">*</span></p>
          <div class="fill_in">
            <div class="col50">
              <input
                type="text"
                placeholder="請輸入您的姓名"
                v-model="userData.name"
                required>
            </div>
            <div class="col50">
              <input
                type="radio"
                name="sex"
                id="m"
                value="m"
                v-model="userData.sex">
              <label for="m"><span></span> 先生</label>
              <input
                type="radio"
                name="sex"
                id="f"
                value="f"
                v-model="userData.sex">
              <label for="f"><span></span> 小姐</label>
            </div>
          </div>
        </div>

        <div class="input_item">
          <p class="mg14">Email<span class="txt-redorange">*</span></p>
          <div class="fill_in">
            <div class="col100">
              <input
                type="text"
                class="readonly"
                readonly
                v-model="userData.email">
            </div>
          </div>
        </div>

        <div class="input_item">
          <p class="mg14">手機<span class="txt-redorange">*</span></p>
          <div class="fill_in">
            <div class="col100">
              <select
                v-model="userData.country_code">
                <option value="">請選擇</option>
                <option
                  v-for="(item, i) in countryPhoneCode"
                  :key="`countryPhoneCode${i}`"
                  :value="item.phone_code">
                    {{item.phone_code_name}}
                  </option>
              </select>
            </div>
            <div class="col100">
              <input
                type="text"
                v-model="userData.mobile"
                placeholder="0912345678"
              >
            </div>
          </div>
        </div>

        <div class="input_item">
          <p class="mg14">聯絡地址<span class="txt-redorange">*</span></p>
          <div class="fill_in">
            <div class="col33">
              <select
                v-model="userData.county">
                <option value="">請選擇</option>
                <option
                  v-for="(item, i) in county"
                  :key="`county${i}`"
                  :value="item">
                    {{item}}
                  </option>
              </select>
            </div>
            <div class="col33">
              <select
                v-model="userData.district">
                <option value="">請選擇</option>
                <option
                  v-for="(item, i) in district"
                  :key="`district${i}`"
                  :value="item">
                    {{item}}
                  </option>
              </select>
            </div>
            <div class="col33">
              <input
                type="text"
                v-model="userData.zipcode"
                readonly
              >
            </div>
            <div class="col100">
              <input
                type="text"
                v-model="userData.address"
                placeholder="請輸入地址"
              >
            </div>
            <!-- <div class="col50">
              <select
                v-model="userData.street">
                <option
                  v-for="(item, i) in streets"
                  :key="`street${i}`"
                  :value="item">
                    {{item}}
                </option>
              </select>
            </div> -->
            <!-- <div class="col50">
              <select
                v-model="userData.range">
                <option
                  v-for="(item, i) in range"
                  :key="`range${i}`"
                  :value="item">
                    {{item}}
                </option>
              </select>
            </div> -->
            <!-- <div class="col50">
              <span class="inputRow">
                <input
                  type="text"
                  style="margin-right: 5px;"
                  v-model="userData.alley">
                <label>巷</label>
              </span>
            </div>
            <div class="col50">
              <span class="inputRow">
                <input
                  type="text"
                  style="margin-right: 5px;"
                  v-model="userData.lane">
                <label>弄</label>
              </span>
            </div>
            <div class="col50">
              <span class="inputRow">
                <input
                  type="text"
                  style="margin-right: 5px;"
                  v-model="userData.number">
                <label>號</label>
              </span>
            </div>
            <div class="col50">
              <span class="inputRow">
                <label>之</label>
                <input
                  type="text"
                  style="margin-left: 5px;"
                  v-model="userData.number_1">
              </span>
            </div>
            <div class="col50">
              <span class="inputRow">
                <input
                  type="text"
                  style="margin-right: 5px;"
                  v-model="userData.floor">
                <label>樓</label>
              </span>
            </div>
            <div class="col50">
              <span class="inputRow">
                <label>之</label>
                <input
                  type="text"
                  style="margin-left: 5px;"
                  v-model="userData.floor_1">
              </span>
            </div>
            <div class="col50">
              <span class="inputRow">
                <input
                  type="text"
                  style="margin-right: 5px;"
                  v-model="userData.room">
                <label>室</label>
              </span>
            </div> -->
          </div>
        </div>

        <div class="input_item">
          <p class="mg14">
            出生日期
            <span class="txt-redorange">*</span>
          </p>
          <div class="fill_in">
            <div class="col100">
              <!-- <input
                type="text"
                class="bir"
                placeholder="MM/DD/YYYY"
                v-model="userData.birthday"
                required> -->
              <date-picker
                type="date"
                v-model="userData.birthday"
                :default-value="userData.birthday"
                :disabledDate="date => disabledDate(date)"
                :style="'width: 100%'"
                placeholder="YYYY-MM-DD">
              </date-picker>
            </div>
          </div>
        </div>

        <div class="btn_item">
          <button
            type="button"
            class="btn btn-blue_gradient"
            @click="sendData();">
              <p>儲存資料</p>
          </button>
        </div>
      </div>
    </form>

  </div>
</template>

<script>
import moment from 'moment';
import DatePicker from 'vue2-datepicker';
import 'vue2-datepicker/locale/zh-cn';
import 'vue2-datepicker/index.css';
import { mapState, mapActions, mapMutations } from 'vuex';
import { setStorage } from '@/utils/timerStorage';
import requestApi from '@/lib/http';

export default {
  name: 'MemberData',
  components: {
    DatePicker,
  },
  data() {
    return {
      countryPhoneCode: [],
      county: [],
      district: [],
      streets: [],
      range: [],
      userData: {
        name: '',
        sex: '',
        email: '',
        country_code: '',
        mobile: '',
        county: '',
        district: '',
        street: '',
        address: '',
        birthday: '',
        range: '',
        alley: '',
        lane: '',
        number: '',
        number_1: '',
        floor: '',
        floor_1: '',
        room: '',
      },
      error: {
        name: '姓名',
        birthday: '生日',
        country_code: '手機國碼',
        mobile: '手機號碼',
        county: '縣市',
        district: '鄉鎮區',
        address: '地址',
        sex: '性別',
      },
    };
  },
  computed: {
    ...mapState([
      'user',
    ]),
  },
  methods: {
    ...mapActions({
      updateUserProfile: 'updateUserProfile',
    }),
    ...mapMutations([
      'setMemberInfo',
    ]),
    setUser() {
      this.userData = {
        name: this.user.name,
        sex: this.user.sex,
        email: this.user.email,
        country_code: this.user.country_code,
        mobile: this.user.mobile,
        county: this.user.county,
        district: this.user.district,
        street: this.user.street,
        address: this.user.address,
        birthday: this.user.birthday,
        range: this.user.range,
        alley: this.user.alley,
        lane: this.user.lane,
        number: this.user.number,
        number_1: this.user.number_1,
        floor: this.user.floor,
        floor_1: this.user.floor_1,
        room: this.user.room,
        zipcode: this.user.zipcode,
      };
      if (this.user.birthday) {
        this.userData.birthday = new Date(moment(this.user.birthday).format('YYYY-MM-DD'));
      } else {
        this.userData.birthday = '';
      }
    },
    async getCountryPhoneCode() {
      this.countryPhoneCode = [];

      const { data } = await requestApi('req.getCountryPhoneCode');
      data.forEach((element) => {
        this.countryPhoneCode.push(element);
      });
    },
    async getCounty() {
      this.county = [];
      const { data } = await requestApi('req.getCounty');

      data.forEach((element) => {
        this.county.push(element.county);
      });
    },
    async getDistrict(county) {
      this.district = [];
      const { data } = await requestApi('req.getDistrict', { county });

      data.forEach((element) => {
        this.district.push(element.district);
      });
    },
    async getZipcode() {
      this.userData.zipcode = null;
      if (
        this.userData.county
        && this.userData.district
      ) {
        const { data } = await requestApi('req.getZipcode', {
          county: this.userData.county,
          district: this.userData.district,
        });
        if (data) {
          this.userData.zipcode = data[0].zipcode;
        }
      }
    },
    checkMemberInfo(data) {
      let check = true;
      if (data) {
        if (Object.keys(data).length > 0) {
          Object.keys(data).forEach((key) => {
            if (
              key === 'name'
              || key === 'sex'
              || key === 'birthday'
              || key === 'country_code'
              || key === 'mobile'
              || key === 'county'
              || key === 'district'
              || key === 'address'
            ) {
              if (data[key] === '' || data[key] === null) {
                check = false;
                this.$customSWAL({
                  icon: 'error',
                  title: `請填寫${this.error[key]}`,
                });
              }
            }
          });
        }
      }
      return check;
    },
    disabledDate(current) {
      // Can not select days after today and today
      return current && current.valueOf() > Date.now();
    },
    async sendData() {
      if (this.checkMemberInfo(this.userData)) {
        let date;
        if (this.userData.birthday) {
          date = new Date(this.userData.birthday);
        }

        const { status, message } = await requestApi('member.updateProfile', {
          ...this.userData,
          birthday: `${date.getFullYear()}-${date.getMonth() + 1}-${date.getDate()}`,
        });

        if (status) {
          this.setMemberInfo(true);
          setStorage('memberInfo', true);
          this.$customSWAL({
            title: message,
            thenFunc: () => {
              this.updateUserProfile();
            },
          });
        } else {
          this.$customSWAL({
            icon: 'error',
            title: message,
          });
        }
      }
    },
  },
  watch: {
    'userData.county': function () {
      if (this.userData.county) {
        this.getDistrict(this.userData.county);
        this.getZipcode();
      }
    },
    'userData.district': function () {
      if (this.userData.district) {
        this.getZipcode();
      }
    },
  },
  mounted() {
    this.setUser();
    this.getCountryPhoneCode();
    this.getCounty();
  },
};
</script>

<style lang="scss" scpoe>
  .inputRow {
    display: flex;
    align-items: center;
  }
</style>
